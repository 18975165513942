import { MessageLinkParams } from '@/communications/messages/models/message';
import { SavedReport } from '@/reports/models/saved-report';
import {
    DateQuery,
    Filter,
    LabelQuery,
    NumberQuery,
    Pivot,
    Report,
    SliceHierarchy,
    TimeQuery,
    ValueQuery
} from 'flexmonster';
import { Status } from '@/families/models/status';
import {
    activeStatusesPlaceholder,
    archiveStatusesPlaceholder,
    RecordIdKeys,
    ReportIdentifier, tourMeetingTypesPlaceholder
} from '@/reports/report-constants';
import { DateFilter, DateRange } from '@/models/date-filter';
import { addDays, formatDateForApi } from '@/date-time/date-time-utils';
import { enableXdebug, vueEnv } from '@/core/env-vars';
import { HierarchyWithType, ReportJobLog, ReportMappingObject, ReportParameters } from '@/reports/models/reports';
import { Location } from 'vue-router';
import { format, fromUnixTime, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { BaseStatuses } from '@/constants/status-constants';
import { getModule } from 'vuex-module-decorators';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import { baseUrl } from '@/core/base-url';
import { ReportDateFiltersService } from '@/reports/report-date-filters-service';
import cloneDeep from 'lodash/cloneDeep';

const authState = getModule(AuthStore, store);

export function getReportNameCollision(reports: Array<SavedReport>, name: string): number | null {
    const collisionReports = reports.filter((report: SavedReport) => {
        // we only care about our own reports
        return report.shared_by === null && !report.is_standard_report && report.name.toLowerCase() === name.toLowerCase();
    });
    if (collisionReports.length) {
        return collisionReports[0].id;
    }
    return null;
}

export function applyDebug(reportUrl: string): string {
    if (vueEnv === 'development' && enableXdebug) {
        const urlObject = new URL(reportUrl);
        const params = new URLSearchParams(urlObject.search);
        params.set('XDEBUG_SESSION_START', 'PHPSTORM');
        urlObject.search = params.toString();

        return decodeURIComponent(urlObject.href);
    }

    return reportUrl;
}

export function adjustReportOrg(reportUrl: string, orgId: number): string {
    const urlObject = new URL(reportUrl);
    const params = new URLSearchParams(urlObject.search);
    params.set('org_ids[]', String(orgId));
    urlObject.search = params.toString();

    return decodeURIComponent(urlObject.href);
}

export function applyReportParameters(reportUrl: string, params: ReportParameters, dashMode = false, dashDateFilter: DateFilter | null = null): string {
    const urlObject = new URL(reportUrl);
    const searchParams = new URLSearchParams(urlObject.search);
    const localParams = cloneDeep(params);

    if (localParams.org_ids) {
        searchParams.delete('org_ids[]');
        for (const orgId of localParams.org_ids) {
            searchParams.append('org_ids[]', String(orgId));
        }
    }

    let start, end;
    if (dashMode) {
        if (dashDateFilter?.startDate) {
            localParams.start_date = dashDateFilter.startDate;
        }
        if (dashDateFilter?.endDate) {
            localParams.end_date = dashDateFilter.endDate;
        }
    }

    if (localParams.start_date) {
        start = Date.parse(localParams.start_date);
        searchParams.set('start_date', localParams.start_date);
    }

    if (localParams.end_date) {
        end = Date.parse(localParams.end_date);
        searchParams.set('end_date', localParams.end_date);
    }

    // Swap dates for people.
    if (localParams.start_date && localParams.end_date && start && end && (end < start)) {
        searchParams.set('end_date', localParams.start_date);
        searchParams.set('start_date', localParams.end_date);
    }

    if (localParams.date_field) {
        searchParams.set('date_field', localParams.date_field);
    }

    if (localParams.from_status_id) {
        searchParams.set('from_status_id', localParams.from_status_id.toString());
    }

    urlObject.search = decodeURI(searchParams.toString());
    urlObject.searchParams.set('t', '' + Date.now());

    return decodeURIComponent(urlObject.href);
}

export function getReportDataUrl(report: ReportIdentifier, params: ReportParameters): string {
    let url;

    if (authState.isAssumingOtherCustomer) {
        url = baseUrl + '/api/admin/v1/customers/' + authState.currentCustomerDb + '/reports/' + report;
    } else {
        url = baseUrl + '/api/v3/reports/' + report;
    }

    return applyReportParameters(url, params);
}

export function getMappingUrl(reportUrl: string): string {
    const urlObject = new URL(reportUrl);
    const params = new URLSearchParams(urlObject.search);

    params.set('getMapping', '1');
    params.set('t', '' + Date.now());
    urlObject.search = params.toString();

    return decodeURIComponent(urlObject.href);
}

export function getReportTypeFromUrl(reportUrl: string): string {
    if (reportUrl.match(/reports\/tasks/)) {
        return 'tasks';
    }
    if (reportUrl.match(/reports\/families/)) {
        return 'families';
    }
    if (reportUrl.match(/reports\/staff/)) {
        return 'staff';
    }
    throw new Error('invalid url');
}

export function adjustForSuperuser(reportUrl: string): string {
    return reportUrl.replace(/\/api\/admin\/v1\/customers\/(\d+)\/reports/, '/api/v3/reports');
}

export function reportExpandStatuses(reportData: Report, statuses: Array<Status>) {
    if (reportData.slice && reportData.slice.rows) {
        for (const row of reportData.slice.rows) {
            if (row.filter && row.filter.members && row.filter.members.length === 1 && row.filter.members[0] === activeStatusesPlaceholder) {
                row.filter.members = statuses.filter((status) => {
                    return !status.is_archive;
                }).map((status) => {
                    return 'status.[' + status.name.toLowerCase() + ']';
                });
            }
            if (row.filter && row.filter.members && row.filter.members.length === 1 && row.filter.members[0] === archiveStatusesPlaceholder) {
                row.filter.members = statuses.filter((status) => {
                    return status.is_archive;
                }).map((status) => {
                    return 'status.[' + status.name.toLowerCase() + ']';
                });
            }
        }
    }
}

export function addTourMeetingTypesToReport(reportData: Report, typeNames: Array<string>) {
    if (reportData.slice && reportData.slice.rows) {
        for (const row of reportData.slice.rows) {
            if (row.filter && row.filter.members && row.filter.members.length === 1 && row.filter.members[0] === tourMeetingTypesPlaceholder) {
                row.filter.members = typeNames.map((name) => {
                    return 'type.[' + name.toLowerCase() + ']';
                });
            }
        }
    }
}

export interface ReportDateRange extends DateRange {
    rangeKey: number;
    label: string;
}

export const defaultReportRange = 30;
export const allReportRange = -1;

export function getReportDateRanges(today: string): Array<ReportDateRange> {
    const lastDays = [7, 30, 90, 365];
    const result: Array<ReportDateRange> = [];
    for (const lastDay of lastDays) {
        result.push({
            rangeKey: lastDay,
            label: `the last ${lastDay} days`,
            startDate: formatDateForApi(addDays(today, -1 * lastDay + 1)),
            endDate: today
        });
    }
    result.push({
        rangeKey: allReportRange,
        label: 'all time',
        startDate: '',
        endDate: ''
    });
    return result;
}

export function getReportLastUpdated(reportJobLogs: Array<ReportJobLog>): string | null {
    if (!reportJobLogs.length) {
        return null;
    }
    return reportJobLogs.map((log) => {
        return log.startDateTime;
    }).sort()[0];
}

/**
 * Get the route parameters for the family hub given the parsed record id.
 *
 * @param recordId
 */
export function getRouteLocationFromRecordId(recordId: string | Record<string, string>): Location {
    const name = 'family-hub';
    const params: Record<string, string> = {};
    const query: Record<string, string> = {};
    if (typeof recordId === 'object') {
        for (const [key, value] of Object.entries(recordId)) {
            if (!value) {
                continue;
            }
            switch (key) {
                case RecordIdKeys.EMAIL:
                    query[MessageLinkParams.OUTBOUND_EMAIL] = value;
                    break;
                case RecordIdKeys.LEAD:
                    params.id = value;
                    break;
                case RecordIdKeys.TEXT:
                    query[MessageLinkParams.TEXT] = value;
                    break;
                default:
                    break;
            }
        }
    } else {
        params.id = recordId;
    }

    return {
        name: name,
        params: params,
        query: query
    };
}

/**
 * Parse the recordId for the report cell/row.
 *
 * @param recordId
 */
export function parseRecordId(recordId: string | number): string | Record<string, string> {
    if (!isNaN(Number(recordId))) {
        return String(recordId);
    }
    const ids = String(recordId).split(',');
    const recordIds: Record<string, string> = {};
    for (const idString of ids) {
        const record = idString.split(':');
        if (!record.length || record.length !== 2) {
            continue;
        }
        recordIds[record[0]] = record[1];
    }

    return recordIds;
}

export function generateSliceObjectFromMap(mapObject: ReportMappingObject): Array<SliceHierarchy> {
    let sliceArray: Array<SliceHierarchy> = [];
    if (mapObject) {
        sliceArray = Object.keys(mapObject).map(item => {
            return { uniqueName: item };
        });
    }
    return sliceArray;
}
/**
 * Capitalizes the first letter of each string value passed in, recognizes comma separated values.
 *
 * @param string
 */
function capitalizeFirstLetter(string: string): string {
    if (string.includes(',')) {
        const stringArray = string.split(',');
        return stringArray.map(string => string.trim().charAt(0).toUpperCase() + string.trim().slice(1)).join(', ');
    } else {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
}

/**
 * Returns the Caption value of a passed in hierarchy in all caps.
 *
 * @param hierarchy
 */
function getCondition(hierarchy: HierarchyWithType): string {
    if (hierarchy.caption) {
        return hierarchy.caption.toUpperCase();
    }
    return '';
}

/**
 * Returns a string representation of the value being filtered, parses dates and times based off Flexmonster's standard Unix timestamp in ms.
 *
 * @param filterItem
 * @param dateFormat
 * @param timeZone
 * @param hierarchy
 * @param hasTimeFormat
 */
function getValues(filterItem: Array<string>, dateFormat: string, timeZone: string, hierarchy: HierarchyWithType, hasTimeFormat = false): string {
    if (filterItem) {
        const values = filterItem.map(member => {
            const match = member.match(/\[(.+?)\]/);
            // return time if mapping object for that hierarchy had a format prop, otherwise return date if its datetime or date string
            if (match && hasTimeFormat) {
                return format(utcToZonedTime(fromUnixTime(parseInt(match[1]) / 1000), timeZone), 'h:mm aa');
            }
            if (match && (hierarchy.type === 'date string' || hierarchy.type === 'datetime')) {
                return format(fromUnixTime(parseInt(match[1]) / 1000), dateFormat);
            }
            return match ? capitalizeFirstLetter(match[1]) : 'empty values';
        });
        return values.join('; ');
    }
    return '';
}

/**
 * Returns a string representation of Query filter operators and values.
 *
 * @param query
 */
function getOperatorAndValue(query: NumberQuery | LabelQuery | DateQuery | TimeQuery | ValueQuery): string {
    if (query.equal) {
        return `${query.equal}`;
    } else if (query.not_equal) {
        return `not ${query.not_equal}`;
    } else if ('begin' in query && query.begin) {
        return `begins with ${query.begin}`;
    } else if ('not_begin' in query && query.not_begin) {
        return `does not begin with ${query.not_begin}`;
    } else if ('end' in query && query.end) {
        return `ends with ${query.end}`;
    } else if ('not_end' in query && query.not_end) {
        return `does not end with ${query.not_end}`;
    } else if ('contain' in query && query.contain) {
        return `contains ${query.contain}`;
    } else if ('not_contain' in query && query.not_contain) {
        return `does not contain ${query.not_contain}`;
    } else if ('greater' in query && query.greater) {
        return `> ${query.greater}`;
    } else if ('greater_equal' in query && query.greater_equal) {
        return `>= ${query.greater_equal}`;
    } else if ('less' in query && query.less) {
        return `< ${query.less}`;
    } else if ('less_equal' in query && query.less_equal) {
        return `<= ${query.less_equal}`;
    } else if (query.between) {
        return `${query.between[0]} to ${query.between[1]}`;
    } else if (query.not_between) {
        return `not between ${query.not_between[0]} & ${query.not_between[1]}`;
    } else if ('top' in query && query.top) {
        return `Top ${query.top}`;
    } else if ('bottom' in query && query.bottom) {
        return `Bottom ${query.bottom}`;
    } else if ('before' in query && query.before) {
        return `before ${query.before}`;
    } else if ('before_equal' in query && query.before_equal) {
        return `<= ${query.before_equal}`;
    } else if ('after' in query && query.after) {
        return `after ${query.after}`;
    } else if ('after_equal' in query && query.after_equal) {
        return `>= ${query.after_equal}`;
    } else if ('last' in query && query.last) {
        return `Last ${query.last}`;
    } else if ('current' in query && query.current) {
        return `Current ${query.current}`;
    } else if ('next' in query && query.next) {
        return `Next ${query.next}`;
    } else {
        return '';
    }
}

export interface InvalidFilterObject {
    name: string;
    invalidValue: string;
}

/**
 * @param{Report} reportData
 * @param{Pivot} flexmonster
 */
export function getInvalidMemberFilters(reportData: Report, flexmonster: Pivot): Array<InvalidFilterObject> {
    const adjustedReport = cloneDeep(reportData);
    if (!adjustedReport.slice || !adjustedReport.slice.rows) {
        return [];
    }

    const invalidFilters: Array<InvalidFilterObject> = [];
    const filterRows = adjustedReport.slice.rows.filter((slice) => {
        return slice.filter && slice.filter.members && slice.filter.members.length > 0;
    });
    for (const filterRow of filterRows) {
        if (!filterRow.filter || !filterRow.filter.members) {
            continue;
        }

        const filterMembers = filterRow.filter.members;
        const reportMembers = flexmonster.getMembers(filterRow.uniqueName, '', () => '');
        const reportMemberNames = reportMembers.map(member => member.uniqueName);
        const intersect = filterMembers.filter(filter => reportMemberNames.includes(filter));
        if (!intersect.length) {
            const invalidValue = getValues(filterMembers, '', '', filterRow);
            invalidFilters.push({ name: filterRow.uniqueName, invalidValue });
        }
    }

    return invalidFilters;
}

export function reportsLoadTheme(theme: string, notMin = false) {
    const links = document.getElementsByTagName('link');
    for (const link of links) {
        if (link.href.match(/flexmonster.*css.*/)) {
            link.remove();
        }
    }
    const newStyle = document.createElement('link');
    newStyle.href = '/fm/themes/' + theme + (notMin ? '/flexmonster.css' : '/flexmonster.min.css');
    newStyle.type = 'text/css';
    newStyle.rel = 'stylesheet';
    document.head.appendChild(newStyle);
}

/**
 * Recursively loops over a string and replaces the default yyyy-MM-dd date value[s] with user's dateFormat
 *
 * @param str
 * @param dateFormat
 */
function reformatDateInString(str: string, dateFormat: string): string {
    // make sure the intended format isn't Flexmonster's default format or bad things happen.
    if (dateFormat === 'yyyy-MM-dd') {
        return str;
    }
    const matcher = new RegExp(/\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])/);
    const match = str.match(matcher) ? str.match(matcher)!.filter(date => date.length === 10)[0] : null;
    if (!match) {
        return str;
    }
    const newStr = str.replace(match, format(parseISO(match), dateFormat));
    return reformatDateInString(newStr, dateFormat);
}

/**
 * Takes a Flexmonster filter item and returns a string representation of the filter for use in UI chips.
 *
 * @param filter
 * @param hierarchy
 * @param dateFormat
 * @param timeZone
 * @param hasTimeFormat
 */
export function getReportFilterLabels(filter: Filter, hierarchy: HierarchyWithType, dateFormat: string, timeZone: string, hasTimeFormat = false): string {
    if (filter.members) {
        return getCondition(hierarchy) + ': ' + getValues(filter.members, dateFormat, timeZone, hierarchy, hasTimeFormat);
    }

    if (filter.query) {
        const operatorAndValue = getOperatorAndValue(filter.query);
        return getCondition(hierarchy) + ': ' + reformatDateInString(operatorAndValue, dateFormat);
    }

    if (filter.exclude || filter.include) {
        const append = filter.exclude ? 'does not contain' : 'contains';
        const filterItem = filter.exclude ? filter.exclude : filter.include;
        return getCondition(hierarchy) + ': ' + append + ' ' + getValues(filterItem!, dateFormat, timeZone, hierarchy, hasTimeFormat);
    }

    return getCondition(hierarchy) + ': ';
}

export interface TimeUnit {
    id: number;
    value: string;
}

export const scheduleTimeUnits: Array<TimeUnit> = [
    {
        id: 1,
        value: 'Days'
    },
    {
        id: 4,
        value: 'Weeks'
    },
    {
        id: 5,
        value: 'Months'
    }
];

/**
 * Add filter to report object that filters out rejected and blank statuses.
 *
 * @param statuses
 * @param row
 */
export function addRejectedStatusAndBlankFilter(statuses: Array<Status>, row: SliceHierarchy | undefined) {
    if (row) {
        // Find the rejected status in the status store.
        const rejectedStatus = statuses.find(status => status.id === BaseStatuses.REJECTED) as Status;

        // Update the entry to have a filter to exclude the rejected status and blank status rows.
        row.filter = {
            exclude: [
                'status.[' + (rejectedStatus.order + 1) + '. ' + rejectedStatus.name + ']',
                'status.[]'
            ]
        };
    }
}

export function getBaseReportIdentifierFromUrl(url: string): ReportIdentifier | null {
    if (url.match(/tasks/)) {
        return ReportIdentifier.TASKS;
    }
    if (url.match(/families-all/)) {
        return ReportIdentifier.ALL_FAMILY;
    }
    if (url.match(/families/)) {
        return ReportIdentifier.FAMILY_STATUS;
    }
    if (url.match(/staff_sessions/)) {
        return ReportIdentifier.STAFF_SESSIONS;
    }
    if (url.match(/staff/)) {
        return ReportIdentifier.STAFF;
    }
    if (url.match(/status-history/)) {
        return ReportIdentifier.STATUS_HISTORY;
    }
    if (url.match(/guardians/)) {
        return ReportIdentifier.GUARDIANS;
    }
    if (url.match(/communications/)) {
        return ReportIdentifier.COMMUNICATIONS;
    }
    if (url.match(/conversion-success/)) {
        return ReportIdentifier.CONVERSION_SUCCESS;
    }
    if (url.match(/marketing-camp/)) {
        return ReportIdentifier.MARKETING_CAMPAIGNS;
    }
    if (url.match(/locations/)) {
        return ReportIdentifier.LOCATIONS;
    }
    return null;
}

export function getDateParametersFromSavedReport(savedReport: Report): ReportParameters {
    const params: ReportParameters = {};
    if (!savedReport.dataSource || !savedReport.dataSource.filename) {
        return params;
    }
    const url = new URL(savedReport.dataSource.filename);
    const urlParams = url.searchParams;
    params.start_date = urlParams.get('start_date') ?? '';
    params.end_date = urlParams.get('end_date') ?? '';
    params.date_field = urlParams.get('date_field') ?? null;
    if (params.date_field === null) {
        const identifier = getBaseReportIdentifierFromUrl(savedReport.dataSource.filename);
        if (identifier) {
            const reportDateService = new ReportDateFiltersService();
            params.date_field = reportDateService.getDefaultFilter(identifier);
        }
    }
    if (urlParams.get('from_status_id')) {
        params.from_status_id = Number(urlParams.get('from_status_id'));
    }
    return params;
}

export function getDefaultReportData(offsetString: string, dateFormat: string, reportUrl: string, mappingUrl: string, reportName: string, token: string): Report {
    return {
        options: {
            viewType: 'grid',
            grid: {
                type: 'flat',
                title: reportName,
                showFilter: false,
                showHeaders: true,
                showTotals: 'off',
                showGrandTotals: 'off',
                showHierarchies: false,
                showHierarchyCaptions: true,
                showReportFiltersArea: true
            },
            chart: {
                type: 'line',
                title: '',
                showFilter: false,
                multipleMeasures: false,
                oneLevel: false,
                autoRange: false,
                reversedAxes: false,
                showLegendButton: false,
                showAllLabels: false,
                showMeasures: true,
                showOneMeasureSelection: true,
                showWarning: true
            },
            configuratorActive: false,
            configuratorButton: true,
            showAggregations: true,
            showCalculatedValuesButton: true,
            editing: false,
            drillThrough: true,
            showDrillThroughConfigurator: true,
            sorting: 'on',
            datePattern: dateFormat,
            dateTimePattern: offsetString + dateFormat,
            saveAllFormats: false,
            showDefaultSlice: false,
            showEmptyData: true,
            defaultHierarchySortName: 'asc',
            selectEmptyCells: true,
            showOutdatedDataAlert: false,
            filter: {
                dateFormat: dateFormat
            }
        },
        formats: [
            {
                name: '',
                thousandsSeparator: '',
                decimalSeparator: '.',
                maxDecimalPlaces: 2,
                maxSymbols: 20,
                currencySymbol: '',
                currencySymbolAlign: 'left',
                nullValue: ' ',
                infinityValue: 'Infinity',
                divideByZeroValue: 'Infinity'
            },
            {
                name: 'percentage',
                isPercent: true
            }
        ],
        localization: {
            grid: {
                dateInvalidCaption: ''
            }
        },
        dataSource: {
            filename: reportUrl,
            // Flexmonster hasn't updated their type definitions to include this option yet
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            useStreamLoader: true,
            // useStreamLoader: false, // Work around for BUGS-1678 till FM fixes it.
            type: 'json',
            mapping: mappingUrl,
            requestHeaders: {
                Authorization: 'Bearer ' + token
            }
        }
    };
}

/**
 * Add filter to report object that filters out statuses based on members
 *
 * @param statuses
 * @param row
 * @param baseIds
 */
export function addDynamicStatusFilters(statuses: Array<Status>, row: SliceHierarchy | undefined, baseIds: Array<number>) {
    if (row) {
        const filteredStatuses = [];
        for (const id of baseIds) {
            const match = statuses.find(status => status.id === id) as Status;
            if (match.order + 1 >= 10) {
                filteredStatuses.push('status.[' + (match.order + 1) + '. ' + match.name.toLowerCase() + ']');
            } else {
                filteredStatuses.push('status.[' + 0 + (match.order + 1) + '. ' + match.name.toLowerCase() + ']');
            }
        }
        row.filter = {
            members: [...filteredStatuses]
        };
    }
}

/**
 * Add filter to report object that filters only on active statuses
 *
 * @param statuses
 * @param row
 */
export function addOnlyActiveStatusFilters(statuses: Array<Status>, row: SliceHierarchy | undefined) {
    if (row) {
        const filteredStatuses = [];
        const activeStatuses = statuses.filter(status => !status.is_archive);
        for (const status of activeStatuses) {
            if (status.order + 1 >= 10) {
                filteredStatuses.push('status.[' + (status.order + 1) + '. ' + status.name.toLowerCase() + ']');
            } else {
                filteredStatuses.push('status.[' + 0 + (status.order + 1) + '. ' + status.name.toLowerCase() + ']');
            }
        }
        row.filter = {
            members: [...filteredStatuses]
        };
    }
}

/**
 * Add filter to report object that filters out statuses based on stringQuery
 *
 * @param statuses
 * @param row
 * @param baseId
 */
export function addDynamicStatusQuery(statuses: Array<Status>, row: SliceHierarchy | undefined, baseId: number) {
    if (row) {
        const match = statuses.find(status => status.id === baseId);
        if (!match) {
            return;
        }
        if (match.order + 1 >= 10) {
            row.filter = {
                query: {
                    equal: match.order + 1 + '. ' + match.name
                }
            };
        } else {
            row.filter = {
                query: {
                    equal: '0' + (match.order + 1) + '. ' + match.name
                }
            };
        }
    }
}
