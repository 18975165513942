























































































































import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { ModalMixin } from '@/core/modal-mixin';
import { ReportsRepository } from '@/reports/repositories/reports-repository';
import { ReportMappingObject, ReportParameters, ReportViewPayload } from '@/reports/models/reports';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { Report, Slice } from 'flexmonster';
import { EventTypes } from '@/constants/event-type-constants';
import { ReportObjectUtils } from '@/reports/report-object-utils';
import { StandardReportType, ConversionSuccessReportPayload, ReportLog } from '@/reports/models/saved-report';
import ReportSelectFieldsLayout from '@/reports/components/select-fields-modal/ReportSelectFieldsLayout.vue';
import ReportFieldsList from '@/reports/components/select-fields-modal/ReportFieldsList.vue';
import ViewRadioOptions from '@/reports/components/select-fields-modal/ViewRadioOptions.vue';
import ReportDateFilter from '@/reports/components/select-fields-modal/ReportDateFilter.vue';
import { ReportDateFiltersService } from '@/reports/report-date-filters-service';
import { InterfaceSettingsStore } from '@/dashboards/store/interface-settings-store';
import { ReportIdentifier, StandardReportIdentifier } from '@/reports/report-constants';
import { StandardReportUtils } from '@/reports/standard-report-utils';
import ConversionTimingView from '@/reports/components/select-fields-modal/ConversionTimingView.vue';
import ConversionSuccessReportSlot from '@/reports/components/select-fields-modal/ConversionSuccessReportSlot.vue';
import { ConversionSuccessReportUtils } from '@/reports/conversion-success-report-utils';
import { Status } from '@/families/models/status';
import { addDays, formatDateForApi } from '@/date-time/date-time-utils';
import { StatusesStore } from '@/families/store/statuses-store';
import { BaseStatuses } from '@/constants/status-constants';
import BaseClose from '@/components/base/BaseClose.vue';

const conversionSuccessReportUtils = new ConversionSuccessReportUtils();
const loadingState = getModule(LoadingStore);
const settingsState = getModule(InterfaceSettingsStore);
const reportDateFilter = new ReportDateFiltersService();
const reportsRepo = new ReportsRepository();
const reportObjectUtils = new ReportObjectUtils();
const statusesStore = getModule(StatusesStore);

@Component({
    components: {
        BaseClose,
        ReportDateFilter,
        ReportSelectFieldsLayout,
        ReportFieldsList,
        ViewRadioOptions,
        ConversionTimingView,
        ConversionSuccessReportSlot
    }
})
export default class ReportSelectFieldsModal extends Mixins(LocaleMixin, ModalMixin) {
    @Prop({ required: true }) activeReport!: ReportIdentifier;
    @Prop() readonly standardReportType!: StandardReportType | null;
    @Prop() readonly reportMappingUrl!: string;
    @Prop({ default: false }) readonly defaultAllSelected!: boolean;
    @Prop({ default: false }) readonly resetValues!: boolean;
    @Prop({ default: false }) readonly savedConversionReport!: boolean;
    @Prop() readonly savedReport!: Report | null;
    @Prop() readonly slice!: Slice;
    @Prop() readonly reportLog!: ReportLog | null;

    private conversionSuccessReportDateInputEvent = EventTypes.CONVERSION_SUCCESS_REPORT_DATE_INPUT;
    private conversionSuccessReportStatusSelectedEvent = EventTypes.CONVERSION_SUCCESS_REPORT_STATUS_SELECTED;
    private convertStatusDate = formatDateForApi(new Date());
    private isLoaded = false;
    private loadingKey = 'reportSelectFieldsModal'
    private mappingObject: ReportMappingObject = {};
    private reportDateFilterEvent = EventTypes.REPORT_DATE_FILTER_UPDATED;
    private selectedStandardReportType: string | StandardReportIdentifier | null = null;
    private selectedFields: Array<string> = [];
    private selectedStatus: Status | null = null;
    private viewRadioOptionsUpdatedEvent = EventTypes.REPORT_VIEW_SELECTED;
    private reportParameters: ReportParameters = {
        start_date: formatDateForApi(addDays(new Date(), -31)),
        end_date: formatDateForApi(addDays(new Date(), -1))
    };

    private specialCanView = false;
    private specialViewReportData: Report | null = null;
    private updatedEvent = EventTypes.UPDATED;

    get canView() {
        if (this.hasFields && this.selectedFields.length === 0) {
            return false;
        }
        if (this.specialViewComponent && !this.specialCanView) {
            return false;
        }
        return true;
    }

    get dateFilterReport() {
        if (this.activeReport) {
            return this.activeReport;
        }
        if (this.standardReportType) {
            return this.standardReportType.identifier;
        }
        return '';
    }

    get hasFields() {
        return StandardReportUtils.hasFields(this.derivedStandardReportIdentifier);
    }

    get hasNormalViews() {
        return StandardReportUtils.hasNormalViews(this.derivedStandardReportIdentifier);
    }

    get isConversionSuccessStandardReport(): boolean {
        return conversionSuccessReportUtils.isConversionSuccessReport(this.standardReportType);
    }

    get isDateFilterable(): boolean {
        return settingsState.preFilterReports &&
            this.activeReport !== ReportIdentifier.LOCATIONS &&
            this.activeReport !== ReportIdentifier.STAFF &&
            this.activeReport !== ReportIdentifier.MARKETING_CAMPAIGNS &&
            StandardReportUtils.shouldShowDateRange(this.derivedStandardReportIdentifier ?? '') &&
            reportDateFilter.isDateFilterable(this.mappingObject);
    }

    get isTrendTracking(): boolean {
        return StandardReportUtils.isTrendTracking(this.derivedStandardReportIdentifier);
    }

    get specialViewComponent() {
        return StandardReportUtils.specialViewComponent(this.derivedStandardReportIdentifier);
    }

    get derivedStandardReportIdentifier() {
        if (this.standardReportType) {
            return this.standardReportType.identifier;
        }
        if (this.savedReport) {
            return StandardReportUtils.deriveStandardReport(this.savedReport);
        }
        if (this.reportLog) {
            return StandardReportUtils.deriveStandardReport(this.reportLog.report_data);
        }
        return null;
    }

    get statusesForConversionSuccess() {
        return statusesStore.statuses.filter(status => !status.is_archive || status.id === BaseStatuses.ENROLLED || status.id === BaseStatuses.LOST_OPP);
    }

    async created() {
        await statusesStore.init();
    }

    @Watch('modelValue')
    private async retrieveFields() {
        if (this.modelValue) {
            loadingState.loadingIncrement(this.loadingKey);
            this.isLoaded = false;
            if (this.resetValues && this.hasFields) {
                this.mappingObject = await reportsRepo.getMapObjectFromUrl(this.reportMappingUrl);
            }
            this.isLoaded = true;
            if (!this.hasNormalViews && this.standardReportType) {
                this.selectedStandardReportType = this.standardReportType.reports[0].standard_report_identifier ?? '';
            }
            if (!StandardReportUtils.shouldShowDateRange(this.derivedStandardReportIdentifier ?? '')) {
                this.updateFilters({});
            }
            loadingState.loadingDecrement(this.loadingKey);
        }
    }

    specialViewUpdated(payload: Report) {
        this.specialViewReportData = payload;
    }

    private updateFilters(payload: ReportParameters): void {
        // bubble it on up
        this.reportParameters = payload;
        this.$emit(EventTypes.REPORT_DATE_FILTER_UPDATED, payload);
    }

    private updateSelectedFields(value: StandardReportIdentifier | string | null) {
        if (this.standardReportType) {
            this.selectedStandardReportType = value;
            this.selectedFields = reportObjectUtils.getSelectedFields(this.selectedStandardReportType, this.standardReportType);
            if (this.isConversionSuccessStandardReport) {
                const statusSet = conversionSuccessReportUtils.buildSetForStatus(this.statusesForConversionSuccess);
                const statusDateSet = conversionSuccessReportUtils.buildSetForStatusDate(this.statusesForConversionSuccess);
                statusSet.forEach(status => {
                    this.selectedFields.push(status);
                });
                statusDateSet.forEach(statusDate => {
                    this.selectedFields.push(statusDate);
                });
            }
        }
    }

    private updatedSelectedStatus(value: Status | null) {
        if (this.isConversionSuccessStandardReport) {
            this.selectedStatus = value;
            this.$emit(EventTypes.CONVERSION_SUCCESS_REPORT_STATUS_SELECTED, this.selectedStatus);
        }
    }

    private updateConvertStatusDate(value: string) {
        this.convertStatusDate = value;
    }

    /**
     * Emit slice object to the parent component
     * @private
     */
    private async viewAction() {
        // Need to set the order back based on this.clonedMappingDisplayItems
        const sortedSelectedFields = reportObjectUtils.sortSelectedFieldsFromMapping(this.selectedFields, this.mappingObject);
        let slice = reportObjectUtils.buildSliceFromArrayOfString(sortedSelectedFields);
        if (this.derivedStandardReportIdentifier === ReportIdentifier.CONVERSION_SUCCESS) {
            const conversionSuccessReportPayload: ConversionSuccessReportPayload = {
                selectedFields: sortedSelectedFields,
                standardReportType: this.standardReportType,
                selectedView: this.selectedStandardReportType,
                selectedStatus: this.selectedStatus,
                convertedStatusDate: this.convertStatusDate,
                isDateFilterable: this.isDateFilterable,
                reportParams: this.reportParameters
            };
            if (this.isConversionSuccessStandardReport) {
                const conversionSuccessSlice = await conversionSuccessReportUtils.convertSliceForConversionSuccessReport(conversionSuccessReportPayload);
                if (conversionSuccessSlice) {
                    slice = conversionSuccessSlice;
                }
            } else {
                if (this.savedConversionReport && this.savedReport && this.savedReport.slice) {
                    slice = this.savedReport.slice;
                    if (slice.reportFilters) {
                        conversionSuccessReportUtils.fixFilters(slice.reportFilters);
                    }
                }
                if (this.savedConversionReport && this.reportLog && this.reportLog.report_data.slice) {
                    slice = this.reportLog.report_data.slice;
                    if (slice.reportFilters) {
                        conversionSuccessReportUtils.fixFilters(slice.reportFilters);
                    }
                }
            }

        }

        const payload: ReportViewPayload = {
            slice
        };
        if (this.selectedStandardReportType) {
            payload.selectedStandardReportType = this.selectedStandardReportType;
        }
        if (this.specialViewComponent && this.specialViewReportData) {
            payload.specialViewReport = this.specialViewReportData;
        }
        this.$emit(EventTypes.REPORT_FIELDS_SELECTED, payload);
        this.close();
    }
}
