











































































































































































































































































































































import { FeatureConstants } from '@/features/feature-constants';
import { FeaturesStore } from '@/features/features-store';
import { LocaleMixin } from '@/locales/locale-mixin';
import { OrgLevelsStore } from '@/organizations/levels/store/org-levels-store';
import { ReportFormatNames } from '@/reports/report-format-constants';
import ShareReportModal from '@/reports/views/ShareReportModal.vue';
import { StaffStore } from '@/staff/store/staff-store';
import cloneDeep from 'lodash/cloneDeep';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { AppStateStore } from '@/store/app-state-store';
import { LoadingStore } from '@/store/loading-store';
import { Org } from '@/models/organization/org';
import { CellData, Hierarchy, Report, Slice, SliceHierarchy, Toolbar } from 'flexmonster';
import Pivot from '@/components/base/Pivot.vue';
import 'flexmonster/lib/flexmonster.highcharts';
import ReportFilterModal from '@/reports/components/ReportFilterModal.vue';
import { flexmonsterKey } from '@/core/env-vars';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import { getGmtOffset } from '@/date-time/date-time-utils';
import { baseLocales } from '@/locales/locales';
import { SavedReportsStore } from '@/reports/store/saved-reports-store';
import { ReportLog, SavedReport, StandardReportGroup, StandardReportType } from '@/reports/models/saved-report';
import { EventTypes } from '@/constants/event-type-constants';
import { SavedReportsRepository } from '@/reports/repositories/saved-reports-repository';
import { clock, close, share, verticaDots } from '@/core/icons/flexmonster-toolbar-icons';
import ReportScheduleModal from '@/reports/components/ReportScheduleModal.vue';
import { getStandardReports } from '@/reports/standard/standard-reports';
import {
    adjustForSuperuser,
    adjustReportOrg,
    applyDebug,
    applyReportParameters,
    defaultReportRange,
    generateSliceObjectFromMap,
    getDefaultReportData,
    getMappingUrl,
    getReportDataUrl,
    getReportFilterLabels,
    getReportLastUpdated,
    getRouteLocationFromRecordId,
    parseRecordId,
    reportExpandStatuses,
    getInvalidMemberFilters,
    reportsLoadTheme
} from '@/reports/report-utils';
import { StatusesStore } from '@/families/store/statuses-store';
import EditSavedReports from '@/reports/components/EditSavedReports.vue';
import { ReportJobLogsStore } from '@/reports/store/report-job-logs-store';
import ReportSelectFieldsModal from '@/reports/components/ReportSelectFieldsModal.vue';
import {
    FilterWithReportFilters,
    ReportMappingObject,
    ReportParameters,
    ReportViewPayload
} from '@/reports/models/reports';
import { InterfaceSettingsStore } from '@/dashboards/store/interface-settings-store';
import AddFilterModal from '@/reports/components/AddFilterModal.vue';
import { ReportsRepository } from '@/reports/repositories/reports-repository';
import { ReportObjectUtils } from '@/reports/report-object-utils';
import { BaseStatuses } from '@/constants/status-constants';
import { DataSources, ReportDataSources, ReportIdentifier } from '@/reports/report-constants';
import { StandardReportUtils } from '@/reports/standard-report-utils';
import { ConversionSuccessReportUtils } from '@/reports/conversion-success-report-utils';
import { Status } from '@/families/models/status';
import { LayoutTabsStore } from '@/store/layout-tabs-store';
import BaseClose from '@/components/base/BaseClose.vue';
import {
    lineLeaderChipBg,
    lineLeaderReportBg,
    lineLeaderReportPrimary,
    reportChipBg,
    reportBg,
    reportPrimary
} from '@/core/colors';
import { DashboardStore } from '@/store/dashboard-store';

const appState = getModule(AppStateStore);
const corpDashState = getModule(DashboardStore);
const authState = getModule(AuthStore, store);
const featuresState = getModule(FeaturesStore);
const loadingState = getModule(LoadingStore);
const orgLevelsState = getModule(OrgLevelsStore);
const savedReportsState = getModule(SavedReportsStore);
const savedReportsRepo = new SavedReportsRepository();
const reportsRepo = new ReportsRepository();
const staffState = getModule(StaffStore);
const statusesStore = getModule(StatusesStore);
const reportJobLogsState = getModule(ReportJobLogsStore);
const settingsState = getModule(InterfaceSettingsStore);
const reportObjectUtils = new ReportObjectUtils();
const conversionSuccessReportUtils = new ConversionSuccessReportUtils();
const layoutTabsState = getModule(LayoutTabsStore, store);

type MappingObject = {
    [key: string]: any;
}

@Component({
    components: {
        BaseClose,
        Pivot,
        ReportFilterModal,
        ReportScheduleModal,
        ShareReportModal,
        EditSavedReports,
        AddFilterModal,
        ReportSelectFieldsModal
    }
})
export default class CustomReports extends Mixins(LocaleMixin) {
    // Props.
    @Prop() readonly logId!: number | null;
    @Prop() readonly standardReportsMode!: boolean | null;
    @Prop() readonly id!: number | undefined;
    @Prop({ default: false }) readonly dashMode!: boolean;
    @Prop() readonly standardReportIdentifier!: string | null;

    // $refs.
    $refs!: {
        pivot: any;
    };

    private standardReports: Array<StandardReportGroup> = [];

    // Properties.
    private activeReport: ReportIdentifier | null = null;
    private activeSavedReport: SavedReport | null = null;
    private activeStandardReport = '';
    private activeReportLog: ReportLog | null = null;
    private actualFilters: Array<FilterWithReportFilters> | Array<any> = [];
    private filterNames: Array<string> = [];
    private filterModal = false;
    private hierarchies: Array<Hierarchy> = [];
    private loadingKey = 'customReports';
    private loaded = false;
    private newReportEvent = EventTypes.NEW_REPORT;
    private reportFilters: Array<Hierarchy> = [];
    private reportFilterUpdateEvent = EventTypes.REPORT_DATE_FILTER_UPDATED;
    private reportMappingUrl = '';
    private reportName = '';
    private reportUrl = '';
    private mappingObject: ReportMappingObject = {};
    private sharedReportEvent = EventTypes.SHARED_REPORT;
    private showShareReportModal = false;
    private showSaveReportModal = false;
    private showSnack = false;
    private showAddFilterModal = false;
    private snackText = '';
    private scheduleModal = false;
    private scheduleAddedEvent = EventTypes.REPORT_SCHEDULE_ADDED;
    private scheduleUpdatedEvent = EventTypes.REPORT_SCHEDULE_UPDATED;
    private scheduleDeletedEvent = EventTypes.REPORT_SCHEDULE_DELETED;
    private filterAddedEvent = EventTypes.REPORT_FILTER_ADDED;
    private savedReportsUpdate = EventTypes.UPDATED;
    private pendingLog = false;
    private reportRangeKey = defaultReportRange;
    private showFieldsModal = false;
    private reportExpansionPanels = [];
    private reportSlice: Slice = {
        rows: [],
        columns: []
    };

    private reportFieldsSelected = EventTypes.REPORT_FIELDS_SELECTED;
    private reportParameters: ReportParameters = {};
    private resetValues = false;
    private defaultAllSelected = false;
    private dataSavedReports: Report | null = null;
    private standardReportType: StandardReportType | null = null;
    // The idea is to have a hash map that keeps all the hierarchies in rows that have filter prop
    private sliceFiltersMap: Map<string, SliceHierarchy> = new Map();
    private conversionSuccessReportStatusSelectedEvent = EventTypes.CONVERSION_SUCCESS_REPORT_STATUS_SELECTED;
    private selectedStatus: Status | null = null;
    private dataReportPayload: ReportViewPayload | null = null;
    private preLoadReport: Report | null = null;
    private savedConversionReport = false;

    get dashDateRange() {
        return corpDashState.storedDateFilter;
    }

    private get orgLevels() {
        return orgLevelsState.stored;
    }

    /**
     * Whether marketing campaigns are enabled.
     */
    get hasMarketingCampaigns() {
        return featuresState.isFeatureEnabled(FeatureConstants.MARKETING_CAMPAIGNS);
    }

    private get lastUpdatedString() {
        const lastUpdated = getReportLastUpdated(reportJobLogsState.stored);
        if (lastUpdated) {
            const timezone = authState.userTimeZone;
            return 'Data last updated ' + this.formatDate(lastUpdated, timezone) + ' @ ' + this.formatTime(lastUpdated, timezone);
        }
        return '';
    }

    private get orgLevelNames(): MappingObject {
        const orgNames: MappingObject = {};
        let shown = false;
        for (let i = 0; i < this.orgLevels.length - 1; i++) {
            if (shown) {
                this.$set(orgNames, this.orgLevels[i].name, {
                    type: 'string',
                    caption: this.orgLevels[i].name
                });
            }
            if (this.org && this.org.level === this.orgLevels[i].id) {
                shown = true;
            }
        }
        this.$set(orgNames, 'center_name', {
            type: 'string',
            caption: 'Location Name'
        });
        return orgNames;
    }

    /**
     * The data sources to show for custom reports.
     *
     * @private
     */
    private get reportTypes(): ReportDataSources {
        const types = cloneDeep(DataSources);
        if (!this.hasMarketingCampaigns) {
            delete types[ReportIdentifier.MARKETING_CAMPAIGNS];
        }
        return types;
    }

    // Get report filter data here.
    private reportData: any = {};

    // The license key for Flexmonster. If none, uses perpetual dev key.
    private licenseKey = flexmonsterKey;

    // Flexmonster pivot configuration.
    private get globalConfig() {
        const ret: any = {
            localization: {
                grid: {
                    blankMember: ' '
                }
            }
        };
        if (this.dashMode) {
            ret.localization.toolbar = {
                export_image: 'Download as Image',
                export_pdf: 'Download as PDF'
            };
        }
        return ret;
    };

    private get dataReports(): Report {
        const offsetString = 'GMT' + getGmtOffset(authState.userTimeZone) + ':';
        const dateFormat = baseLocales[this.$i18n.locale].dateFormat;
        const report = getDefaultReportData(offsetString, dateFormat, this.reportUrl, this.reportMappingUrl, this.reportName, authState.token ?? '');
        report.slice = this.reportSlice;
        if (!report.formats) {
            report.formats = [];
        }
        report.formats.push(
            {
                name: ReportFormatNames.CURRENCY,
                currencySymbol: this.currencySymbol,
                decimalPlaces: 2,
                decimalSeparator: this.currencyDecimalSeparator,
                thousandsSeparator: this.currencyThousandsSeparator
            }
        );
        return report;
    }

    get chipBg() {
        if (featuresState.isLineLeaderEnroll) {
            return lineLeaderChipBg;
        }
        return reportChipBg;
    }

    get filterIconStyle() {
        if (featuresState.isLineLeaderEnroll) {
            return 'fill:' + lineLeaderReportBg + ';stroke:' + lineLeaderReportPrimary + ';stroke-width:1';
        }
        return 'fill:' + reportBg + ';stroke:' + reportPrimary + ';stroke-width:1';
    }

    // Getters.
    get org() {
        return appState.storedCurrentOrg;
    }

    get statuses() {
        return statusesStore.statuses;
    }

    get statusesForConversionSuccess() {
        return statusesStore.statuses.filter(status => !status.is_archive || status.id === BaseStatuses.ENROLLED || status.id === BaseStatuses.LOST_OPP);
    }

    get savedReports() {
        return savedReportsState.stored.filter((report) => {
            return !report.is_standard_report;
        });
    }

    get activeSavedReportId(): number {
        return this.activeSavedReport ? this.activeSavedReport.id : 0;
    }

    get hasInContactMode(): boolean {
        return featuresState.isFeatureEnabled(FeatureConstants.INCONTACT);
    }

    get isOnEnrollmentTeam(): boolean {
        return featuresState.isFeatureEnabled(FeatureConstants.ENROLLMENT_CENTER) && authState.userInfoObject!.is_on_enrollment_team;
    }

    get userId(): number {
        return authState.userInfoObject!.id;
    }

    get helpUrl() {
        return this.standardReportsMode
            ? 'http://educate.lineleader.com/en/articles/5691545-using-standard-reports-crm'
            : 'http://educate.lineleader.com/en/articles/5410256-using-custom-reports-crm';
    }

    // Setters.

    // Watches
    @Watch('dashDateRange', { deep: true })
    async dashDateRangeChanged() {
        await this.refreshReport();
    }

    @Watch('logId')
    public async logRequested() {
        if (this.logId) {
            await this.loadLog();
        } else {
            this.activeReportLog = null;
        }
    }

    @Watch('reportRangeKey')
    public async rangeUpdated() {
        this.loaded = false;
        loadingState.loadingStop(this.loadingKey);
        loadingState.loadingIncrement(this.loadingKey);

        if (this.activeReport) {
            await this.updateReport();
        }

        if (this.activeReportLog || this.activeSavedReport) {
            await this.refreshReport();
        }

        loadingState.loadingDecrement(this.loadingKey);
    }

    @Watch('org')
    public async orgUpdated(org: Org | null, oldOrg: Org | null) {
        this.loaded = false;

        loadingState.loadingStop(this.loadingKey);
        loadingState.loadingIncrement(this.loadingKey);

        if (this.activeReport && org && org.id) {
            this.reportParameters.org_ids = [org.id];

            // Flexmonster updateData doesn't seem to do well with mapping changes.
            if (oldOrg && oldOrg.level === org.level) {
                await this.updateReport();
            } else {
                await this.changeReport();
            }
        }

        if (this.activeReportLog || this.activeSavedReport) {
            if (org && org.id) {
                this.reportParameters.org_ids = [org.id];
            }

            if (this.dataReportPayload) {
                await this.setCorrectDataForReport(this.dataReportPayload);
            } else {
                await this.refreshReport();
            }
        }

        loadingState.loadingDecrement(this.loadingKey);
    }

    @Watch('standardReportsMode')
    public modeChanged() {
        this.activeReport = null;
        this.activeReportLog = null;
        this.activeSavedReport = null;
    }

    @Watch('id', { immediate: true })
    public async preloadedId() {
        if (this.id) {
            await savedReportsState.init(this.userId);
            const preloadedReports = this.savedReports.filter((report) => {
                return report.id === Number(this.id);
            });
            if (preloadedReports.length > 0) {
                if (this.dashMode) {
                    await this.activateSavedReport(preloadedReports[0]);
                    this.loadReport();
                } else {
                    await this.openDiffSavedReportFieldsModal(preloadedReports[0]);
                }
            }
        }
    }

    @Watch('standardReportIdentifier', { immediate: true })
    public async preselectedStandard() {
        if (this.standardReportIdentifier) {
            const standardReports = await getStandardReports();
            for (const reportGroup of standardReports) {
                for (const report of reportGroup.reports) {
                    for (const view of report.reports) {
                        if (view.standard_report_identifier === this.standardReportIdentifier) {
                            await this.activateSavedReport(view);
                            this.loadReport();
                        }
                    }
                }
            }
        }
    }

    // Lifecycle events.
    async created() {
        loadingState.loadingIncrement(this.loadingKey);

        const reportsResponse = savedReportsState.init(this.userId);
        // Make sure all staff are retrieved and refreshed everytime this page is reached since it won't be used often
        const allStaffResponse = staffState.retrieveAllStaff();
        const featuresResponse = featuresState.init();
        const statusesResponse = statusesStore.init();
        const reportJobLogsPromise = reportJobLogsState.init();
        const settingsResponse = settingsState.init();
        await reportsResponse;
        await allStaffResponse;
        await featuresResponse;
        await statusesResponse;
        await reportJobLogsPromise;
        await settingsResponse;
        if (this.org && this.org.id) {
            this.reportParameters.org_ids = [this.org.id];
        }

        this.standardReports = await this.getStandardReports();

        loadingState.loadingDecrement(this.loadingKey);
        this.$refs.pivot.flexmonster.on(
            'filterclose',
            () => this.checkFiltersAreValid()
        );

        if (featuresState.isLineLeaderEnroll) {
            reportsLoadTheme('custom', true);
        } else {
            reportsLoadTheme('lightblue');
        }

        if (this.logId) {
            await this.loadLog();
        }

        this.$refs.pivot.flexmonster.on('reportcomplete', () => this.reportComplete());
    }

    /**
     * Apply formatting to certain columns after the report is loaded.
     */
    private applyFormats(): void {
        if (this.activeReport) {
            let report: Report | null = null;
            if (this.activeReport) {
                report = this.dataReports;
            }
            if (this.activeSavedReport || this.activeStandardReport) {
                if (this.dataSavedReports) {
                    report = this.dataSavedReports;
                }
            }
            if (this.activeReportLog) {
                report = this.activeReportLog.report_data;
            }

            if (report) {
                reportObjectUtils.applyReportFormats(this.activeReport, report);
            }
        }
    }

    beforeDestroy() {
        // unregister event listeners
        this.$refs.pivot.flexmonster.of('reportchange');
        this.$refs.pivot.flexmonster.off('reportcomplete');
        this.$refs.pivot.flexmonster.off('filterclose');
    }

    clearFilters() {
        this.reportParameters = {};
        this.filterNames = [];
        this.actualFilters = [];
        this.sliceFiltersMap.clear();
    }

    async setReportParameters(parameters: ReportParameters): Promise<void> {
        // Set date filters; don't clobber org filter
        this.reportParameters.start_date = parameters.start_date;
        this.reportParameters.end_date = parameters.end_date;
        this.reportParameters.date_field = parameters.date_field;
        if (parameters.from_status_id) {
            this.reportParameters.from_status_id = parameters.from_status_id;
        }
        await this.updateReportUrls();
    }

    // Methods.
    private getStatusNameForCustom(standardReportName: string): string {
        if (standardReportName === 'Wait List') {
            return this.statuses.find(status => status.id === BaseStatuses.WAIT_LIST)!.name;
        }
        if (standardReportName === 'Lost Opportunity') {
            return this.statuses.find(status => status.id === BaseStatuses.LOST_OPP)!.name;
        }
        return standardReportName;
    }

    private generateChipLabel(filter: FilterWithReportFilters, index: number) {
        if (filter.reportFilter) {
            // If its from a report filter, it's already formatted so just return the string.
            return filter.reportFilter.label;
        }

        const hierarchy = this.hierarchies.find(hierarchy => hierarchy.uniqueName === this.filterNames[index]);
        if (!hierarchy) {
            this.filterNames.splice(index, 1);
            this.checkFiltersAreValid();
            return;
        }

        const timeZone = authState.userTimeZone;
        const dateFormat = baseLocales[this.$i18n.locale].dateFormat;
        const hasTimeFormat = !!this.mappingObject[hierarchy.uniqueName!].format;

        return getReportFilterLabels(filter, hierarchy, dateFormat, timeZone, hasTimeFormat);
    }

    async getStandardReports(): Promise<Array<StandardReportGroup>> {
        return await getStandardReports();
    }

    private async loadLog() {
        if (!this.logId) {
            return;
        }

        loadingState.loadingIncrement(this.loadingKey);
        this.activeReport = null;
        this.activeSavedReport = null;
        this.activeReportLog = await savedReportsRepo.getOneReportLog(this.userId, this.logId);
        this.pendingLog = true;
        loadingState.loadingDecrement(this.loadingKey);

        if (this.org && this.org.id) {
            this.loadReportLogData();
        }
    }

    private loadReportLogData() {
        if (!this.activeReportLog) {
            return;
        }

        this.$refs.pivot.flexmonster.clear();
        this.clearFilters();
        const reportData = this.activeReportLog.report_data;
        if (reportData && reportData.slice) {
            this.sliceFiltersMap = reportObjectUtils.buildMapFromSlice(reportData.slice);
        }

        this.updateSavedReportUrls(reportData);
        this.resetValues = true;
        this.defaultAllSelected = false;
        if (StandardReportUtils.deriveStandardReport(reportData) === ReportIdentifier.CONVERSION_SUCCESS) {
            this.savedConversionReport = true;
        }
        this.showFieldsModal = true;
        this.pendingLog = false;
    }

    private updateFromReportData(reportData: Report) {
        if (!reportData) {
            return;
        }

        if (reportData.dataSource) {
            if (reportData.dataSource.filename && this.org && this.org.id) {
                reportData.dataSource.filename = adjustReportOrg(reportData.dataSource.filename, this.org.id);
            }

            if (reportData.dataSource.filename) {
                reportData.dataSource.filename = applyDebug(reportData.dataSource.filename);

                if (this.activeSavedReport) {
                    reportData.dataSource.filename = this.adjustReportUrl(reportData.dataSource.filename);
                }

                reportData.dataSource.mapping = getMappingUrl(reportData.dataSource.filename);
            }

            reportData.dataSource.requestHeaders = {
                Authorization: 'Bearer ' + authState.token
            };

            // Flexmonster hasn't updated their type definitions to include this option yet
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            reportData.dataSource.useStreamLoader = true;
            // reportData.dataSource.useStreamLoader = false; // Work around for BUGS-1678 till FM fixes it.
        }

        this.preProcessReport(reportData);
        this.preLoadReport = cloneDeep(reportData);
        // in dash mode, make sure we're always re-applying filters, otherwise filters will get lost if there's no data
        // for certain ranges
        if (this.dashMode && reportData.slice?.rows) {
            reportData.slice.rows = reportObjectUtils.buildSliceWithMap(reportData.slice.rows, this.sliceFiltersMap, reportData.slice.rows);
        }
        this.$refs.pivot.flexmonster.setReport(reportData);

        if (this.activeSavedReport && this.activeSavedReport.is_standard_report && this.activeSavedReport.expand_centers) {
            this.$refs.pivot.flexmonster.expandData('center_name');
        }

        this.setClick();
        this.$refs.pivot.flexmonster.refresh();
    }

    private reportShared() {
        this.snackText = 'The shared recipient(s) will now be able to see this report under their "My Saved Reports" section.';
        this.showSnack = true;
    }

    private scheduleAdded() {
        this.snackText = 'Your report has been scheduled.  Click "Schedule" again to view or edit the schedule.';
        this.showSnack = true;
    }

    private scheduleUpdated() {
        this.snackText = 'Your report schedule has been updated.';
        this.showSnack = true;
    }

    private scheduleDeleted() {
        this.snackText = 'Your report schedule has been cancelled.';
        this.showSnack = true;
    }

    private postLoadFormatting() {
        if (this.activeSavedReport && this.activeSavedReport.standard_report_identifier) {
            StandardReportUtils.postLoadFormatting(this.activeSavedReport.standard_report_identifier, this.$refs.pivot.flexmonster);
        }
    }

    // After closing filter modal and on report load,
    // this checks that filters were actually applied (instead of hitting cancel)
    // or if the filter is already active before adding them to the chips.
    private checkFiltersAreValid() {
        if (this.activeReport || this.activeSavedReport) {
            this.loaded = true;
        }

        if (this.preLoadReport) {
            // another layer of report postprocessing... :-(
            const invalidMemberFilters = getInvalidMemberFilters(this.preLoadReport, this.$refs.pivot.flexmonster);
            for (const invalidFilter of invalidMemberFilters) {
                this.$refs.pivot.flexmonster.setFilter(invalidFilter.name, { query: { equal: invalidFilter.invalidValue } });
            }

            this.preLoadReport = null;
        }

        this.hierarchies = this.$refs.pivot.flexmonster.getRows();
        this.reportFilters = this.$refs.pivot.flexmonster.getReportFilters();
        this.filterNames = [...new Set(this.filterNames.filter(name => this.$refs.pivot.flexmonster.getFilter(name)))];

        this.actualFilters = this.filterNames.map(name => this.$refs.pivot.flexmonster.getFilter(name));
        if (this.reportFilters && this.reportFilters.length) {
            // add report filters BACK to the hierarchies list (they get removed by flex) and check if filters are saved in report and add those back to names
            this.reportFilters.forEach(filter => {
                this.hierarchies.push(filter);
                if (filter.uniqueName && this.$refs.pivot.flexmonster.getFilter(filter.uniqueName)) {
                    this.filterNames.push(filter.uniqueName);
                    this.filterNames = [...new Set(this.filterNames.filter(name => this.$refs.pivot.flexmonster.getFilter(name)))];
                    this.actualFilters = this.filterNames.map(name => this.$refs.pivot.flexmonster.getFilter(name));
                }
            });

            // Check that filters don't already exist for them,
            // and then add them to the end of the actual filters array
            this.actualFilters = [
                ...this.actualFilters,
                ...this.reportFilters
                    .filter(filter => !this.filterNames.find(name => name === filter.uniqueName))
                    .map(
                        filter => {
                            return {
                                reportFilter: {
                                    label: `${filter.caption?.toUpperCase()}: All`,
                                    uniqueName: filter.uniqueName
                                }
                            };
                        }
                    )
            ];
        }
    }

    // Opens filter modal and adds uniqueName to filter array for chips
    private filterAdded(filter: string) {
        this.$refs.pivot.flexmonster.openFilter(filter);
        this.filterNames.push(filter);
    }

    // opens filter modal when clicking on filter chips
    private editFilter(filter: FilterWithReportFilters, index: number) {
        if (filter.reportFilter) {
            this.$refs.pivot.flexmonster.openFilter(filter.reportFilter.uniqueName);
        }

        this.$refs.pivot.flexmonster.openFilter(this.filterNames[index]);
    }

    private async updateSavedReports() {
        let found = false;
        for (const report of this.savedReports) {
            if (report.id === this.activeSavedReport?.id) {
                this.activeSavedReport = report;
                found = true;
            }
        }

        if (!found) {
            this.activeSavedReport = null;
        }

        this.snackText = 'Saved Reports Updated.';
        this.showSnack = true;
    }

    private adjustReportUrl(reportUrl: string): string {
        if (!authState.isAssumingOtherCustomer) {
            reportUrl = adjustForSuperuser(reportUrl);
        }

        return applyReportParameters(reportUrl, this.reportParameters, this.dashMode, this.dashDateRange);
    }

    private async changeReport() {
        await this.updateReportUrls();
        const mappingPromise = reportsRepo.getMapObjectFromUrl(this.reportMappingUrl);
        this.mappingObject = await mappingPromise;
        this.reportSlice.rows = generateSliceObjectFromMap(this.mappingObject);
        this.preLoadReport = cloneDeep(this.dataReports);
        this.$refs.pivot.flexmonster.setReport(this.dataReports);
        this.setClick();
        this.$refs.pivot.flexmonster.refresh();
    }

    private setClick() {
        this.$refs.pivot.flexmonster.off('celldoubleclick');
        this.$refs.pivot.flexmonster.on('celldoubleclick', async (cell: CellData) => {
            if (cell.recordId && !Array.isArray(cell.recordId)) {
                const routeData = this.$router.resolve(
                    getRouteLocationFromRecordId(parseRecordId(cell.recordId))
                );

                if (this.hasInContactMode && this.isOnEnrollmentTeam) {
                    await layoutTabsState.addTab({
                        routeName: 'family-hub',
                        routeParams: routeData.route.params,
                        goTo: true
                    });
                } else {
                    window.open(routeData.href, '_blank');
                }
            }
        });
    }

    private async refreshReport() {
        if (!this.org || !this.org.id) {
            return;
        }

        if (this.pendingLog) {
            this.loadReport();
            this.pendingLog = false;
            return;
        }

        loadingState.loadingIncrement(this.loadingKey);
        await orgLevelsState.retrieveAll();
        loadingState.loadingDecrement(this.loadingKey);
        const currentReportData = this.$refs.pivot.flexmonster.getReport();
        this.updateFromReportData(currentReportData);
    }

    private async updateReport() {
        await this.updateReportUrls();
        const mappingPromise = reportsRepo.getMapObjectFromUrl(this.reportMappingUrl);
        this.mappingObject = await mappingPromise;
        this.reportSlice.rows = generateSliceObjectFromMap(this.mappingObject);
        this.$refs.pivot.flexmonster.updateData({
            filename: this.reportUrl,
            type: 'json',
            mapping: this.reportMappingUrl,
            // Flexmonster hasn't updated their type definitions to include this option yet
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            useStreamLoader: true,
            // useStreamLoader: false, // Work around for BUGS-1678 till FM fixes it.
            requestHeaders: {
                Authorization: 'Bearer ' + authState.token
            }
        });
    }

    private getReportURL(): string {
        if (!this.activeReport || !this.reportParameters) {
            return '';
        }

        return getReportDataUrl(this.activeReport, this.reportParameters);
    }

    private openFilterModal(): void {
        this.reportData = this.$refs.pivot.flexmonster.getReport();
        this.filterModal = true;
    }

    private openShareReportModal(): void {
        if (this.activeReportLog) {
            this.$swal({
                icon: 'warning',
                text: 'Logs cannot be shared'
            });
            return;
        }
        if (!this.activeSavedReport) {
            this.$swal({
                icon: 'warning',
                text: 'Only saved reports can be shared. Please save this report first.'
            });
            return;
        }
        if (this.activeSavedReport.is_standard_report) {
            this.$swal({
                icon: 'warning',
                text: 'Only saved reports can be shared.  Please save this report first.'
            });
            return;
        }
        if (!this.activeSavedReport.is_editable) {
            this.$swal({
                icon: 'warning',
                text: 'Only your reports can be shared.'
            });
            return;
        }
        if (this.activeSavedReport && this.activeSavedReport.is_editable) {
            this.showShareReportModal = true;
        } else {
            this.$swal({
                icon: 'info',
                text: 'Please save this report before trying to share it.'
            });
        }
    }

    private openScheduleModal(): void {
        if (this.activeReportLog) {
            this.$swal({
                icon: 'warning',
                text: 'Logs cannot be scheduled'
            });
            return;
        }
        if (!this.activeSavedReport) {
            this.$swal({
                icon: 'warning',
                text: 'Only saved reports can be scheduled. Please save this report first.'
            });
            return;
        }
        if (!this.activeSavedReport.is_editable && !this.activeSavedReport.is_standard_report) {
            this.$swal({
                icon: 'warning',
                text: 'Only your reports can be scheduled.'
            });
            return;
        }

        this.scheduleModal = true;
    }

    private preProcessReport(reportData: Report) {
        this.adjustReportFormats(reportData);
        this.adjustReportDateFormat(reportData);
        reportExpandStatuses(reportData, this.statuses);

        if (reportData.options && reportData.options.grid) {
            reportData.options.grid.title = this.dashMode ? undefined : this.currentReportName();
        }
        if (this.dashMode && reportData.options) {
            reportData.options.configuratorButton = false;
        }
    }

    private adjustReportDateFormat(reportData: Report) {
        const offsetString = 'GMT' + getGmtOffset(authState.userTimeZone) + ':';
        const dateFormat = baseLocales[this.$i18n.locale].dateFormat;
        if (reportData.options) {
            reportData.options.datePattern = dateFormat;
            reportData.options.dateTimePattern = offsetString + dateFormat;
            if (reportData.options.filter) {
                reportData.options.filter.dateFormat = dateFormat;
            }
        }
    }

    /**
     * Adjust report formatting that needs to be localized correctly.
     *
     * @param reportData
     * @private
     */
    private adjustReportFormats(reportData: Report) {
        reportObjectUtils.setCurrencyFormatting(reportData, this.currencySymbol, this.currencyDecimalSeparator, this.currencyThousandsSeparator);
    }

    private closeReport() {
        this.activeReportLog = null;
        this.activeSavedReport = null;
        this.activeReportLog = null;
        this.$router.push({ name: 'previously-scheduled' });
    }

    private newReport(newReport: SavedReport) {
        this.activeReport = null;
        this.activeSavedReport = newReport;
        this.$refs.pivot.flexmonster.setOptions({
            grid: {
                showFilter: false,
                title: this.currentReportName()
            },
            chart: {
                showFilter: false
            }
        });
        if (this.standardReportsMode) {
            this.$router.push({
                name: 'custom-reports',
                params: { id: String(newReport.id) }
            });
        }
    }

    // This is where we have to do funny stuff to customize the buttons.. =(
    private customizeToolbar(toolbar: Toolbar): void {
        const tabs = toolbar.getTabs();
        toolbar.getTabs = () => {
            if (this.dashMode) {
                return tabs.filter(t => ['fm-tab-export', 'fm-tab-fullscreen'].includes(t.id)).map(t => {
                    if (t.id === 'fm-tab-export') {
                        t.icon = verticaDots;
                        t.rightGroup = true;
                        t.menu = t.menu!.filter(m => ['fm-tab-export-pdf', 'fm-tab-export-image'].includes(m.id)).map(m => {
                            m.title = m.title!.replace(/To/, 'Download as');
                            return m;
                        });
                    }
                    return t;
                });
            }
            // Get rid of the 'Connect' tab. It's useless for our case.
            delete tabs[tabs.map(function(e) {
                return e.id;
            }).indexOf('fm-tab-connect')];
            delete tabs[tabs.map(function(e) {
                return e.id;
            }).indexOf('fm-tab-open')];

            // Replace the 'Save' tab's functionality to save a filter.
            const saveTab = tabs[tabs.map(function(e) {
                return e.id;
            }).indexOf('fm-tab-save')];
            saveTab.title = 'Save Filter';
            saveTab.handler = this.openFilterModal;

            tabs.push({
                id: 'fm-tab-share',
                title: 'Share',
                handler: this.openShareReportModal,
                icon: share
            });

            // schedule icon
            tabs.push({
                id: 'fm-tab-schedule',
                title: 'Schedule',
                handler: this.openScheduleModal,
                icon: clock
            });

            if (this.logId) {
                tabs.push({
                    id: 'fm-tab-close',
                    title: 'Close',
                    handler: this.closeReport,
                    rightGroup: true,
                    icon: close
                });
            }

            return tabs;
        };
    }

    /**
     * Callback for when the Flexmonster chart is completed.
     */
    private reportComplete() {
        this.checkFiltersAreValid();
        this.postLoadFormatting();
    }

    /**
     * Get the name to show for the saved report.
     * Show the shared name if the report is not owned by the current user.
     *
     * @param savedReport
     */
    private savedReportName(savedReport: SavedReport): string {
        if (!savedReport.is_editable && savedReport.shared_name !== '') {
            return savedReport.shared_name;
        }

        return savedReport.name;
    }

    currentReportName() {
        if (this.activeSavedReport) {
            return this.savedReportName(this.activeSavedReport);
        }

        if (this.activeReportLog) {
            return this.activeReportLog.name;
        }

        return this.reportName;
    }

    private async activateReport(reportId: ReportIdentifier) {
        this.activeReport = reportId;
        this.activeSavedReport = null;
        this.dataSavedReports = null;
        this.activeReportLog = null;
        this.activeStandardReport = '';
        this.standardReportType = null;
        this.savedConversionReport = false;
        this.reportName = this.reportTypes[reportId]?.name ?? '';

        await this.updateReportUrls();
    }

    private async activateSavedReport(savedReport: SavedReport) {
        this.activeReport = null;
        this.activeReportLog = null;
        this.activeSavedReport = savedReport;
        this.activeStandardReport = '';
        this.standardReportType = null;
        this.savedConversionReport = false;
        let reportData;
        if (!savedReport.is_standard_report) {
            loadingState.loadingIncrement(this.loadingKey);
            const fullReport = await savedReportsRepo.getOneReport(this.userId, savedReport.id);
            reportData = JSON.parse(fullReport.report_data);
            loadingState.loadingDecrement(this.loadingKey);
        } else if (this.dashMode) {
            reportData = JSON.parse(savedReport.report_data);
        }
        if (StandardReportUtils.deriveStandardReport(reportData) === ReportIdentifier.CONVERSION_SUCCESS) {
            this.savedConversionReport = true;
        }
        this.dataSavedReports = reportData;
        if (this.dataSavedReports && this.dataSavedReports.slice) {
            this.sliceFiltersMap = reportObjectUtils.buildMapFromSlice(this.dataSavedReports.slice);
        }

        this.updateSavedReportUrls(reportData);
    }

    private async openDiffStandardReportFieldModal(standardReportType: StandardReportType) {
        this.$refs.pivot.flexmonster.clear();
        this.dataSavedReports = null;
        this.clearFilters();
        this.standardReportType = standardReportType;
        this.activeStandardReport = standardReportType.identifier;
        this.activeReport = null;
        this.activeSavedReport = null;
        this.activeReportLog = null;
        this.savedConversionReport = false;
        const reportData = JSON.parse(standardReportType.reports[0].report_data);
        if (reportData && reportData.slice) {
            this.sliceFiltersMap = reportObjectUtils.buildMapFromSlice(reportData.slice);
        }
        this.updateSavedReportUrls(reportData);
        this.resetValues = true;
        this.defaultAllSelected = false;
        this.showFieldsModal = true;
    }

    private async openDiffSavedReportFieldsModal(savedReport: SavedReport) {
        this.$refs.pivot.flexmonster.clear();
        this.clearFilters();
        await this.activateSavedReport(savedReport);
        this.resetValues = true;
        this.defaultAllSelected = false;
        this.showFieldsModal = true;
    }

    private async openSameSavedReportFieldsModal() {
        this.resetValues = false;
        this.defaultAllSelected = false;
        const slice = reportObjectUtils.getSlice(this.$refs.pivot.flexmonster);
        if (slice) {
            this.reportSlice = slice;
        }

        this.showFieldsModal = true;
    }

    private async openDiffReportFieldsModal(reportId: ReportIdentifier) {
        this.$refs.pivot.flexmonster.clear();
        this.clearFilters();
        await this.activateReport(reportId);
        this.resetValues = true;
        this.defaultAllSelected = true;
        this.showFieldsModal = true;
    }

    private async openSameReportFieldsModal() {
        this.resetValues = false;
        this.defaultAllSelected = false;
        const slice = reportObjectUtils.getSlice(this.$refs.pivot.flexmonster);
        if (slice) {
            this.reportSlice = slice;
        }
        this.showFieldsModal = true;
    }

    private async setCorrectDataForReport(payload: ReportViewPayload) {
        this.loaded = false;
        this.dataReportPayload = null;

        if (payload.specialViewReport) {
            if (this.standardReportType && this.standardReportType.reports) {
                this.activeSavedReport = this.standardReportType.reports[0];
            }

            this.dataSavedReports = payload.specialViewReport;
            const derivedIdentifier = StandardReportUtils.deriveStandardReport(this.dataSavedReports);
            if (derivedIdentifier && this.activeSavedReport) {
                this.activeSavedReport.standard_report_identifier = derivedIdentifier;
            }

            this.loadReport();

            return;
        }

        const mappingPromise = reportsRepo.getMapObjectFromUrl(this.reportMappingUrl);
        this.mappingObject = await mappingPromise;
        this.filterNames = [];
        const slice = payload.slice;
        const selectedStandardReportType = payload.selectedStandardReportType;

        if (slice && slice.rows) {
            if (this.activeReport) {
                this.reportSlice = slice;
            }

            if (this.activeSavedReport) {
                if (this.dataSavedReports && this.dataSavedReports.slice) {
                    if (this.dataSavedReports.options) {
                        this.dataSavedReports.options.chart!.showFilter = false;
                        this.dataSavedReports.options.grid!.showFilter = false;
                    }
                    if (this.savedConversionReport) {
                        this.dataReportPayload = payload;
                        this.dataSavedReports.slice.reportFilters = slice.reportFilters;
                        this.dataSavedReports.slice.measures = slice.measures;
                        const statuses = conversionSuccessReportUtils.buildStatusesArray(this.selectedStatus, this.statusesForConversionSuccess);
                        this.dataSavedReports.formats = conversionSuccessReportUtils.generateFormat(statuses);
                    }

                    this.dataSavedReports.slice.flatOrder = slice.flatOrder;
                    this.dataSavedReports.slice.rows = reportObjectUtils.buildSliceWithMap(slice.rows, this.sliceFiltersMap, this.dataSavedReports.slice.rows ?? []);
                    this.filterNames = reportObjectUtils.getFilterNames(this.dataSavedReports.slice.rows);
                }
            }

            if (this.activeReportLog) {
                if (this.activeReportLog.report_data && this.activeReportLog.report_data.slice) {
                    if (this.activeReportLog.report_data.options) {
                        this.activeReportLog.report_data.options.chart!.showFilter = false;
                        this.activeReportLog.report_data.options.grid!.showFilter = false;
                    }
                    this.activeReportLog.report_data.slice.flatOrder = slice.flatOrder;
                    this.activeReportLog.report_data.slice.rows = reportObjectUtils.buildSliceWithMap(slice.rows, this.sliceFiltersMap, this.activeReportLog.report_data.slice.rows ?? []);
                    this.filterNames = reportObjectUtils.getFilterNames(this.activeReportLog.report_data.slice.rows);
                }
            }

            if (selectedStandardReportType && this.standardReportType && this.activeStandardReport) {
                const report = this.standardReportType.reports.find(report => report.standard_report_identifier === selectedStandardReportType);
                if (report) {
                    this.activeSavedReport = report;
                    const reportData = JSON.parse(report.report_data);
                    reportData.slice.flatOrder = slice.flatOrder;

                    if (conversionSuccessReportUtils.isConversionSuccessReport(this.standardReportType)) {
                        this.dataReportPayload = payload;
                        reportData.slice.reportFilters = slice.reportFilters;
                        reportData.slice.measures = slice.measures;
                        reportData.slice.rows = reportObjectUtils.buildSliceWithMap(slice.rows, this.sliceFiltersMap, reportData.slice.rows);
                        const statuses = conversionSuccessReportUtils.buildStatusesArray(this.selectedStatus, this.statusesForConversionSuccess);
                        if (conversionSuccessReportUtils.hasPercentageCalculations(selectedStandardReportType)) {
                            reportData.formats = conversionSuccessReportUtils.generateFormat(statuses);
                        }
                    } else {
                        const rows = StandardReportUtils.getSortedRows(reportData, slice.rows);
                        reportData.slice.rows = reportObjectUtils.buildSliceWithMap(rows, this.sliceFiltersMap, reportData.slice.rows);
                    }

                    this.filterNames = reportObjectUtils.getFilterNames(reportData.slice.rows);
                    this.dataSavedReports = reportData;
                }
            }
        }

        this.loadReport();
    }

    private async updateReportUrls() {
        if (!this.activeReport) {
            return;
        }

        if (!this.org || !this.org.id) {
            return;
        }

        // Always hard refresh org levels to make sure our token is updated.
        await orgLevelsState.retrieveAll();

        this.reportUrl = this.getReportURL();
        this.reportUrl = applyDebug(this.reportUrl);
        this.reportUrl = this.adjustReportUrl(this.reportUrl);

        this.reportMappingUrl = getMappingUrl(this.reportUrl);
    }

    private updateSavedReportUrls(reportData: Report) {
        if (!reportData) {
            return;
        }

        if (reportData.slice) {
            this.reportSlice = reportData.slice;
        }
        if (reportData.dataSource) {
            if (reportData.dataSource.filename && this.org && this.org.id) {
                this.reportUrl = adjustReportOrg(reportData.dataSource.filename, this.org.id);
                this.reportUrl = applyDebug(this.reportUrl);
                if (this.activeSavedReport) {
                    this.reportUrl = this.adjustReportUrl(this.reportUrl);
                }
                this.reportMappingUrl = getMappingUrl(this.reportUrl);
            }
        }
    }

    private loadReport() {
        this.applyFormats();
        if (this.activeReport) {
            this.preLoadReport = cloneDeep(this.dataReports);
            this.$refs.pivot.flexmonster.setReport(this.dataReports);
            this.setClick();
            this.$refs.pivot.flexmonster.refresh();
        }
        if (this.activeSavedReport || this.activeStandardReport) {
            if (this.dataSavedReports) {
                this.updateFromReportData(this.dataSavedReports);
            }
        }
        if (this.activeReportLog) {
            this.updateFromReportData(this.activeReportLog.report_data);
        }
    }

    private updatedSelectedStatus(value: Status | null) {
        if (conversionSuccessReportUtils.isConversionSuccessReport(this.standardReportType)) {
            this.selectedStatus = value;
            this.reportParameters.from_status_id = value?.id;
        }
    }
}
